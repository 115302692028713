import { BaseBean } from './base-bean.class';
import { HouseholdMember } from './household-member.class';

export class ProgramSelected extends BaseBean {
    constructor(
        public recordId: number = undefined,
        public programId: number = undefined,
        public programName: string = '',
        public programStatusCode: string = '',
        public applicationDate: Date = null,
        public selectedIndicator: string = '',
        public programSubType: string = '',
        public finalizeProgramIndicator: string = '',
        public programMode: string = '',
        public msnapInd: string = '',
        public spendDownBeginDt :string = null,
        public rescndDate: Date = null,
        public rescndReason: string = null,
        public spendDownEndDt :string = null 
    ) {
        super();
    }
}
