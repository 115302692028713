import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiManager } from 'app/common/api-manager.service';
import { HistoryPopupComponent } from './history-popup.component';
import { ConstantsService } from 'app/common/constants.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    HistoryPopupComponent
  ],
  exports: [
    HistoryPopupComponent
  ]
})

export class HistoryPopupModule {

  ngOnInit() {
  }

  close() {
  }

 }
