import {BaseBean} from "./base-bean.class";
export class AppointmentSchedulingClass extends BaseBean{
  public programInterviewScheduleId: number = undefined;
  public fsInd: string = '';
  public chInd: string = '';
  public maInd: string = '';
  public eaInd: string = '';
  public startTime: string = '';
  public endTime;
  public interviewDate;
  public interviewType;
  public missedInterviewDate;
  public interviewStatus;
  public ldssRecordId;
  public phoneNumber;
  public caseMode;
  public editMode;
  public scheduleDate;
  public redetDate;
}
